<template>
	<div id="header-template" class="container"> 
        <div class="row">
			<div class="col-2 pt-2">
				<h1 class="logo" >
                    <span class="logo-prefix">new</span>DEF
                </h1>
			</div>
			<div class="col text-center">

                <nav class="navbar navbar-expand-lg navbar-light">

                    <button class="navbar-toggler ml-auto" type="button" 
                        data-toggle="collapse" data-target="#navbarSupportedContent" 
                        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>

                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav ml-auto">
                            <li class="nav-item" >
                                <a class="nav-link" href="#about">O NAS</a>
                            </li>
                            <li class="nav-item" >
                                <a class="nav-link" href="#offer">OFERTA</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#tech">TECHNOLOGIA</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#contact">KONTAKT</a>
                            </li>
                        </ul>
                    </div>
		        </nav>
            </div>
		</div>
    </div>
</template>

<script>
    export default {
        name: 'header-template'
    }
</script>

<style>
</style>