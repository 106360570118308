<template>
	<header-index></header-index>
	<div class="baner">
		<h1 class="text-baner">Zaprogramuj z nami swoją przyszłość</h1>
	</div>
	<div class="container">
		<div class="row">
			<div class="col" style="text-align: center;">
				<h1 id="about" class="mt-5">O nas</h1>
				<span class="separator"></span>
			</div>
		</div>

		<div class="row">
			<div class="col-12">
				<h3>
					Tworzymy, rozwijamy i utrzymujemy dedykowane systemy i aplikacje informatyczne
				</h3>
				<p class="text-secondary">
					Nasi pracownicy posiadają wieloletnie doświadczenie w projektowaniu i wytwarzaniu oprogramowania dla firm z różnych sektorów gospodarki.
				</p>
			</div>
		</div>

		<div class="row mt-5">
			<div class="col" style="text-align: center;">
				<h1 id="offer">Nasza oferta</h1>
				<span class="separator"></span>
			</div>
		</div>

		<div class="row">
			<div class="col-12 col-sm-6 col-md-4 mb-5" style="text-align: center;">
				<img src="./assets/image/pc.jpg" style="width: 100%;" alt="PC">
				<h3 class="mt-2">Aplikacje dekstopowe</h3>
				<p class="text-secondary">
					wieloplatformowe aplikacje desktopowe, dopasowane do celów Twojego biznesu, zaprojektowane z myślą o potrzebach Twoich klientów i pracowników
				</p>
			</div>

			<div class="col-12 col-sm-6 col-md-4 mb-5" style="text-align: center;">
				<img src="./assets/image/mobile.jpg" style="width: 100%;" alt="MOBILE">
				<h3 class="mt-2">Aplikacje mobilne</h3>
				<p class="text-secondary">
					dedykowane aplikacje mobilne, w pełni zintegrowane ze smartfonem lub tabletem
				</p>
			</div>

			<div class="col-12 col-sm-6 col-md-4 mb-5" style="text-align: center;">
				<img src="./assets/image/web.jpg" style="width: 100%;" alt="WEB">
				<h3 class="mt-2">Aplikacje webowe</h3>
				<p class="text-secondary">
					responsywne interfejsy graficzne dostosowujące rozmiar strony do rozdzielczości ekranu urządzenia, kompatybilne z popularnymi przeglądarkami stron internetowych
				</p>
			</div>
		</div>

		<div class="row">
			<div class="col" style="text-align: center;">
				<h1 id="tech">Technologie</h1>
				<span class="separator"></span>
			</div>
		</div>

		<div class="row">
			<div class="col-12" style="text-align: center;">
				<img class="ml-5 mr-5 mb-5" src="./assets/image/vue.png" style="width: 100px" alt="Vue.js">
				<img class="ml-5 mr-5 mb-5" src="./assets/image/bootstrap.png" style="width: 120px" alt="Bootstrap">
				<img class="ml-5 mr-5 mb-5" src="./assets/image/java.png" style="width: 150px" alt="Java">
				<img class="ml-5 mr-5 mb-5" src="./assets/image/spring.png" style="width: 250px" alt="Spring">
				<img class="ml-5 mr-5 mb-5" src="./assets/image/dotnet_round.png" style="width: 250px" alt=".Net">
				<img class="ml-5 mr-5 mb-5" src="./assets/image/postgresql.jpg" style="width: 150px" alt="PostgreSql">
				<img class="ml-5 mr-5 mb-5" src="./assets/image/microsoft-sql-server-logo.png" style="width: 150px" alt="Microsoft SQL Server">
			</div>
		</div>

		<div class="row mt-5">
			<div class="col" style="text-align: center;">
				<h1 id="contact">Kontakt</h1>
				<span class="separator"></span>
			</div>
		</div>

		<div class="row">
			<div class="col">
				<p class="text-secondary" style="font-size: 1.2rem;">
					<a href="mailto:biuro@newdef.pl">biuro@newdef.pl</a><br><br>
					NEWDEF Dariusz Bujak<br>
					NIP: 737-21-35-390<br>
					REGON: 386047494
				</p>
			</div>
		</div>
	</div>

	<div class="container-fluid text-center mt-5 mb-5">
		<span class="text-muted">Wszelkie prawa zastrzeżone Copyright © 2021 newdef.pl</span>
    </div>
</template>

<script>
    import HeaderIndex from '@/components/Header.vue'

    export default 
    {
        name: 'app',
        components: {
            'header-index':HeaderIndex
        }
	}
</script>

<style>
	@import'~bootstrap/dist/css/bootstrap.css';


	.baner {
		background-image: url('./assets/image/baner.jpg');
		background-repeat: no-repeat;
		background-attachment: fixed;
		background-color: black;
		height: 250px;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
	}
	.text-baner {
		font-size: 2.5rem;
		font-family: 'Poppins', sans-serif;
		/*
		gradient
		text-transform: uppercase;
		background: linear-gradient(0.25turn, #000000, #fafafa, #000000);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		*/
		-webkit-text-stroke: 1px rgb(0, 0, 0);
		color: rgb(255, 255, 255);
	}

	.separator {
		width:40px;  
		height:3px;  
		background:#0d6efd;  
		margin-top:10px;  
		margin-bottom:30px;  
		left:50%; 
		margin-left: -20px;
		position: relative;
		float: left; 
	}
</style>